const actions = {
    general: {
        SET_INTEGRATION_PARAMS: 'SET_INTEGRATION_PARAMS',
        FETCH_CONFIG: 'FETCH_CONFIG',
        FETCH_CONFIG_SUCCESS: 'FETCH_CONFIG_SUCCESS',
        FETCH_CONFIG_ERROR: 'FETCH_CONFIG_ERROR',
        FETCH_RESULT: 'FETCH_RESULT',
        FETCH_RESULT_SUCCESS: 'FETCH_RESULT_SUCCESS',
        FETCH_RESULT_ERROR: 'FETCH_RESULT_ERROR',
    },
};

export default actions;
