import actions from '../actions/types';
import GeneralState from '../../types/GeneralState';
import StoreAction from '../../types/StoreAction';
import { updateObject } from './utility';

const initialState: GeneralState = {
    result: {
        // адепт: 2,
        // актер: 0,
        // аналитик: 0,
        // боец: 1,
        // визионер: 0,
        // вождь: 1,
        // интуит: 0,
        // исполнитель: 1,
        // конструктор: 1,
        // контролер: 0,
        // организатор: 0,
        // преследователь: 0,
        // связной: -1,
        // творец: 1,
        // хакер: 1,
        // хозяин: 2,
        // экспериментатор: 1,
        // эксперт: 2,
        // эмпат: 1,
        // эстет: 1,
        east: 0,
        north: 0,
        northeast: 0,
        northwest: 0,
        south: 0,
        southeast: 0,
        southwest: 0,
        west: 0,
    },
    fetchConfig: false,
    fetchConfigError: null,
    fetchResult: false,
    fetchResultError: null,
    integrationParams: {
        token: '',
        unti_id: '',
        activity_uuid: '',
    },
    source_activity_uuid: '',
};

export default (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.general.SET_INTEGRATION_PARAMS: {
            let currentState = Object.assign({}, state);
            currentState.integrationParams = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG: {
            let currentState = Object.assign({}, state);
            currentState.fetchConfig = true;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG_SUCCESS: {
            let currentState = Object.assign({}, state);

            currentState = {
                ...currentState,
                ...action.payload,
            };
            currentState.fetchConfig = false;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG_ERROR: {
            let currentState = Object.assign({}, state);
            currentState.fetchConfigError = action.payload;
            currentState.fetchConfig = false;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_RESULT: {
            let currentState = Object.assign({}, state);
            currentState.fetchResult = true;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_RESULT_SUCCESS: {
            let currentState = Object.assign({}, state);

            currentState.result = action.payload;
            currentState.fetchResult = false;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_RESULT_ERROR: {
            let currentState = Object.assign({}, state);
            currentState.fetchResultError = action.payload;
            currentState.fetchResult = false;

            return updateObject(state, currentState);
        }
        default:
            return state;
    }
};
